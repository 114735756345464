import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const ninja = document.querySelector('ninja-keys');
    ninja.data = [
      {
        id: 'GroupReport',
        title: '集团级报表',
        section: '报表',
        children: ['business_target_org_show','operation_summary_org','year_report_history','subsidiaries_operating_comparison','design_cash_flow'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'business_target_org_show',
        title: '集团营业目标完成情况',
        parent: 'GroupReport',
        handler: () => {
          Turbolinks.visit("/account/business_target_org_show");
        },
      },
      {
        id: 'operation_summary_org',
        title: '集团经营情况汇总',
        parent: 'GroupReport',
        handler: () => {
          Turbolinks.visit("/account/operation_summary_org");
        },
      },
      {
        id: 'year_report_history',
        title: '创意板块整体经营情况',
        parent: 'GroupReport',
        handler: () => {
          Turbolinks.visit("/report/year_report_history?view_orgcode_sum=true");
        },
      },
      {
        id: 'subsidiaries_operating_comparison',
        title: '子公司经营情况对比',
        parent: 'GroupReport',
        handler: () => {
          Turbolinks.visit("/report/subsidiaries_operating_comparison?view_orgcode_sum=true");
        },
      },
      {
        id: 'design_cash_flow',
        title: '集团级子公司现金流情况',
        parent: 'GroupReport',
        handler: () => {
          Turbolinks.visit("/report/design_cash_flow?view_orgcode_sum=true");
        },
      },
      {
        id: 'GroupHRMonth',
        title: '集团人力资源月报',
        section: '报表',
        children: ['rt_group_hr_monthly','professional_and_incentive_level','rt_group_hr_monthly_pure','group_hr_monthly','group_hr_monthly_pure','hr_cjld','hr_core_staff_in_and_out','hr_staff_in_and_out','hr_year_dismiss','employee_list','resignation_process_ledger','parttime_job_salary_location'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'rt_group_hr_monthly',
        title: '集团人力资源月报（实时）',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/rt_group_hr_monthly");
        },
      },
      {
        id: 'professional_and_incentive_level',
        title: '集团人力月报-专业和激励等级',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/group/professional_and_incentive_level");
        },
      },
      {
        id: 'rt_group_hr_monthly_pure',
        title: '集团人力资源月报（实时）- HR',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/rt_group_hr_monthly_pure");
        },
      },
      {
        id: 'group_hr_monthly',
        title: '集团人力资源月报',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/group_hr_monthly");
        },
      },
      {
        id: 'group_hr_monthly_pure',
        title: '集团人力资源月报 - HR',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/group_hr_monthly_pure");
        },
      },
      {
        id: 'hr_cjld',
        title: '人力资源层级流动',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/hr_cjld");
        },
      },
      {
        id: 'hr_core_staff_in_and_out',
        title: '核心人员入离职名单',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/hr_core_staff_in_and_out");
        },
      },
      {
        id: 'hr_staff_in_and_out',
        title: '人力资源入离职图',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/hr_staff_in_and_out");
        },
      },
      {
        id: 'hr_year_dismiss',
        title: '一年滚动累计离职率',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/hr_year_dismiss");
        },
      },
      {
        id: 'employee_list',
        title: '集团人员信息',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/employee_list");
        },
      },
      {
        id: 'resignation_process_ledger',
        title: '离职流程台账查看',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/group/resignation_process_ledger");
        },
      },
      {
        id: 'parttime_job_salary_location',
        title: '薪资发放地兼职查看',
        parent: 'GroupHRMonth',
        handler: () => {
          Turbolinks.visit("/report/parttime_job_salary_location");
        },
      },
      {
        id: 'GroupOperations',
        title: '子公司运营情况总览',
        section: '报表',
        children: ['group_daily_workloading','complete_value','group_contract_hold','group_predict_contract'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'group_daily_workloading',
        title: '集团每日工作填报率及饱和度分析',
        parent: 'GroupOperations',
        handler: () => {
          Turbolinks.visit("/report/group_daily_workloading?view_orgcode_sum=true");
        },
      },
      {
        id: 'complete_value',
        title: '工作完成情况分析',
        parent: 'GroupOperations',
        handler: () => {
          Turbolinks.visit("/report/complete_value?view_orgcode_sum=true");
        },
      },
      {
        id: 'group_contract_hold',
        title: '集团业务保有量',
        parent: 'GroupOperations',
        handler: () => {
          Turbolinks.visit("/report/group_contract_hold?view_orgcode_sum=true");
        },
      },
      {
        id: 'group_predict_contract',
        title: '集团跟踪合同额',
        parent: 'GroupOperations',
        handler: () => {
          Turbolinks.visit("/report/group_predict_contract?view_orgcode_sum=true");
        },
      },
      {
        id: 'SubsidiaryReport',
        title: '子公司级管理',
        section: '报表',
        children: ['business_target_org_show','operation_summary_dept','subsidiary_contract_signing','subsidiary_department_receive','subsidiary_design_cash_flow','subsidiary_project_profit_summary'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'business_target_dept_show',
        title: '子公司营业目标完成情况',
        parent: 'SubsidiaryReport',
        handler: () => {
          Turbolinks.visit("/account/business_target_dept_show");
        },
      },
      {
        id: 'operation_summary_dept',
        title: '公司经营情况汇总',
        parent: 'SubsidiaryReport',
        handler: () => {
          Turbolinks.visit("/account/operation_summary_dept");
        },
      },
      {
        id: 'subsidiary_contract_signing',
        title: '本年累计合同额及签约周期 所级商务合同额 / 生产合同额',
        parent: 'SubsidiaryReport',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_contract_signing?view_deptcode_sum=true");
        },
      },
      {
        id: 'subsidiary_department_receive',
        title: '部门收款情况 所级实收款 / 应收款',
        parent: 'SubsidiaryReport',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_department_receive?view_deptcode_sum=true");
        },
      },
      {
        id: 'subsidiary_design_cash_flow',
        title: '子公司现金流情况 所级设计现金流',
        parent: 'SubsidiaryReport',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_design_cash_flow?view_deptcode_sum=true");
        },
      },
      {
        id: 'subsidiary_project_profit_summary',
        title: '子公司项目利润汇总表',
        parent: 'SubsidiaryReport',
        handler: () => {
          Turbolinks.visit("/financial_analysis/subsidiary_project_profit_summary");
        },
      },
      {
        id: 'SubsidiaryHR',
        title: '子公司人力资源月报',
        section: '报表',
        children: ['rt_group_subsidiary_hr_monthly','subsidiary_professional_and_incentive_level','group_subsidiary_hr_monthly','group_subsidiary_hr_monthly_pure','subsidiary_hr_cjld','hr_talent_pool','personnel_incentive_category','key_account_exam_result','qualification_exam_result'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'rt_group_subsidiary_hr_monthly',
        title: '子公司人力资源月报（实时）',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/rt_group_subsidiary_hr_monthly");
        },
      },
      {
        id: 'subsidiary_professional_and_incentive_level',
        title: '子公司人力月报 - 分专业和激励等级',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/account/subsidiary_professional_and_incentive_level");
        },
      },
      {
        id: 'group_subsidiary_hr_monthly',
        title: '子公司人力资源月报',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/group_subsidiary_hr_monthly");
        },
      },
      {
        id: 'group_subsidiary_hr_monthly_pure',
        title: '子公司人力资源月报 - HR',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/group_subsidiary_hr_monthly_pure");
        },
      },
      {
        id: 'subsidiary_hr_cjld',
        title: '子公司人力资源 - 层级流动',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_hr_cjld");
        },
      },
      {
        id: 'hr_talent_pool',
        title: '人力资源月报 - 层级明细',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/hr_talent_pool");
        },
      },
      {
        id: 'personnel_incentive_category',
        title: '子公司人员激励类别',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/personnel_incentive_category");
        },
      },
      {
        id: 'key_account_exam_result',
        title: '大客户考试成绩',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/key_account_exam_result");
        },
      },
      {
        id: 'qualification_exam_result',
        title: '任职资格考试成绩',
        parent: 'SubsidiaryHR',
        handler: () => {
          Turbolinks.visit("/report/qualification_exam_result");
        },
      },
      {
        id: 'SubsidiaryOperation',
        title: '子公司所级运营情况',
        section: '报表',
        children: ['subsidiary_complete_value','contract_hold','predict_contract','department_cancellation','application_entrepreneurship_incubation','application_for_negative_cash_flow'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'subsidiary_complete_value',
        title: '所级完成产值 部门工作完成情况',
        parent: 'SubsidiaryOperation',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_complete_value?view_deptcode_sum=true");
        },
      },
      {
        id: 'contract_hold',
        title: '所级业务保有量',
        parent: 'SubsidiaryOperation',
        handler: () => {
          Turbolinks.visit("/report/contract_hold?view_deptcode_sum=true");
        },
      },
      {
        id: 'predict_contract',
        title: '跟踪合同额',
        parent: 'SubsidiaryOperation',
        handler: () => {
          Turbolinks.visit("/report/predict_contract?view_deptcode_sum=true");
        },
      },
      {
        id: 'department_cancellation',
        title: '生产部门撤销报告',
        parent: 'SubsidiaryOperation',
        handler: () => {
          Turbolinks.visit("/report/department_cancellation");
        },
      },
      {
        id: 'application_entrepreneurship_incubation',
        title: '创业孵化补贴申请',
        parent: 'SubsidiaryOperation',
        handler: () => {
          Turbolinks.visit("/subsidiary/application_entrepreneurship_incubation");
        },
      },
      {
        id: 'application_for_negative_cash_flow',
        title: '部门现金流负值真实补提申请',
        parent: 'SubsidiaryOperation',
        handler: () => {
          Turbolinks.visit("/subsidiary/application_for_negative_cash_flow");
        },
      },
      {
        id: 'OfficeContract',
        title: '所级管理',
        section: '报表',
        children: ['overall_department_operating_status', 'dept_homepage','contract_information_form','departmental_market_fees','subsidiary_need_receive_sign_detail','subsidiary_need_receive_factoring','subsidiary_need_receive_unsign_detail','margin_statement','summary_operating_receivables_ratio','detail_operating_receivables_ratio'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'overall_department_operating_status',
        title: '所级管理 部门总体经营情况',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/overall_department_operating_status");
        },
      },
      {
        id: 'dept_homepage',
        title: '所级管理 部门概览',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/dept_homepage");
        },
      },
      {
        id: 'contract_information_form',
        title: '所级合同管理表',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/contract_information_form");
        },
      },
      {
        id: 'departmental_market_fees',
        title: '所级合同市场费管理表',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/departmental_market_fees");
        },
      },
      {
        id: 'subsidiary_need_receive_sign_detail',
        title: '所级合同应收款明细',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_need_receive_sign_detail");
        },
      },
      {
        id: 'subsidiary_need_receive_factoring',
        title: '所级合同应收保理手续费明细',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_need_receive_factoring");
        },
      },
      {
        id: 'subsidiary_need_receive_unsign_detail',
        title: '所级合同未签约业务应收明细',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_need_receive_unsign_detail");
        },
      },
      {
        id: 'margin_statement',
        title: '所级合同保证金报表',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/margin_statement");
        },
      },
      {
        id: 'summary_operating_receivables_ratio',
        title: '经营应收占比汇总',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/summary_operating_receivables_ratio");
        },
      },
      {
        id: 'detail_operating_receivables_ratio',
        title: '经营应收占比明细',
        parent: 'OfficeContract',
        handler: () => {
          Turbolinks.visit("/report/detail_operating_receivables_ratio");
        },
      },
      {
        id: 'OfficeOperation',
        title: '所级项目运营',
        section: '报表',
        children: ['project_contract_summary','single_project_overview','bonus_distribution','subsidiary_daily_workloading','subsidiary_people_workloading','project_milestore', 'project_tracking_status_sheet','project_income_statement'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'project_contract_summary',
        title: '所级项目运营项目管理表',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/project_contract_summary");
        },
      },
      {
        id: 'single_project_overview',
        title: '所级项目运营项目查询表',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/single_project_overview");
        },
      },
      {
        id: 'bonus_distribution',
        title: '所级项目运营 产值汇总表',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/bonus_distribution");
        },
      },
      {
        id: 'subsidiary_working_hours',
        title: '所级项目运营 所级工时查看',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_working_hours");
        },
      },
      {
        id: 'subsidiary_daily_workloading',
        title: '所级项目运营 工作饱和度',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_daily_workloading?view_deptcode_sum=true");
        },
      },
      {
        id: 'subsidiary_people_workloading',
        title: '所级项目运营 人员饱和度',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/subsidiary_people_workloading?view_deptcode_sum=true");
        },
      },
      {
        id: 'project_milestore',
        title: '所级项目运营 项目进度填报情况',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/project_milestore");
        },
      },
      {
        id: 'project_tracking_status_sheet',
        title: '所级项目运营 项目跟踪情况表',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/project_tracking_status_sheet");
        },
      },
      {
        id: 'project_income_statement',
        title: '所级项目运营 项目利润表',
        parent: 'OfficeOperation',
        handler: () => {
          Turbolinks.visit("/report/project_income_statement");
        },
      },
      {
        id: 'MarketReport',
        title: '市场模块报表',
        section: '报表',
        children: ['contract_provice_area','contract_types_analysis','contracts_geographical_analysis','contract_sign_detail'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'contract_provice_area',
        title: '市场模块 市场占有率',
        parent: 'MarketReport',
        handler: () => {
          Turbolinks.visit("/report/contract_provice_area");
        },
      },
      {
        id: 'contract_types_analysis',
        title: '市场模块 商务合同类型分析',
        parent: 'MarketReport',
        handler: () => {
          Turbolinks.visit("/report/contract_types_analysis");
        },
      },
      {
        id: 'contracts_geographical_analysis',
        title: '市场模块 商务合同地域分析',
        parent: 'MarketReport',
        handler: () => {
          Turbolinks.visit("/report/contracts_geographical_analysis");
        },
      },
      {
        id: 'contract_sign_detail',
        title: '市场模块 已归档合同签约明细',
        parent: 'MarketReport',
        handler: () => {
          Turbolinks.visit("/report/contract_sign_detail");
        },
      },
      {
        id: 'SallerReport',
        title: '商务人员分析报表',
        section: '报表',
        children: ['people_contract_signing','people_department_receive','people_predict_contract'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'people_contract_signing',
        title: '商务人员分析 - 合同额',
        parent: 'SallerReport',
        handler: () => {
          Turbolinks.visit("/report/people_contract_signing");
        },
      },
      {
        id: 'people_department_receive',
        title: '商务人员分析 - 实收款',
        parent: 'SallerReport',
        handler: () => {
          Turbolinks.visit("/report/people_department_receive?view_deptcode_sum=true");
        },
      },
      {
        id: 'people_predict_contract',
        title: '商务人员分析 - 跟踪合同额',
        parent: 'SallerReport',
        handler: () => {
          Turbolinks.visit("/report/people_predict_contract?view_deptcode_sum=true");
        },
      },
      {
        id: 'CustomerReport',
        title: '客户模块',
        section: '报表',
        children: ['key_customer_detail','crm_year_report','customer_receivable_accounts','customer_actual_payments','split_customer_billing_amount','subsidiary_major_account_management_fee'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'key_customer_detail',
        title: '客户模块 核心客户分析',
        parent: 'CustomerReport',
        handler: () => {
          Turbolinks.visit("/report/key_customer_detail");
        },
      },
      {
        id: 'crm_year_report',
        title: '客户模块 客户商务合同额情况',
        parent: 'CustomerReport',
        handler: () => {
          Turbolinks.visit("/report/crm_year_report");
        },
      },
      {
        id: 'customer_receivable_accounts',
        title: '客户模块 客户应收款情况',
        parent: 'CustomerReport',
        handler: () => {
          Turbolinks.visit("/report/customer_receivable_accounts");
        },
      },
      {
        id: 'customer_actual_payments',
        title: '客户模块 客户实收款情况',
        parent: 'CustomerReport',
        handler: () => {
          Turbolinks.visit("/report/customer_actual_payments");
        },
      },
      {
        id: 'split_customer_billing_amount',
        title: '客户模块 客户开票额拆分情况',
        parent: 'CustomerReport',
        handler: () => {
          Turbolinks.visit("/report/split_customer_billing_amount");
        },
      },
      {
        id: 'subsidiary_major_account_management_fee',
        title: '客户模块 大客户服务费',
        parent: 'CustomerReport',
        handler: () => {
          Turbolinks.visit("/account/subsidiary_major_account_management_fee");
        },
      },
      {
        id: 'FinancialFilling',
        title: '财务分析数据填报',
        section: '报表',
        children: ['org_year_fill','dept_year_fill','cost_structure_entry','cash_flow_dept_entry','three_categories_manager'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'org_year_fill',
        title: '财务分析数据 公司级经营计划填报',
        parent: 'FinancialFilling',
        handler: () => {
          Turbolinks.visit("/account/org_year_fill");
        },
      },
      {
        id: 'dept_year_fill',
        title: '财务分析数据 所级经营计划填报',
        parent: 'FinancialFilling',
        handler: () => {
          Turbolinks.visit("/account/dept_year_fill");
        },
      },
      {
        id: 'cost_structure_entry',
        title: '财务分析数据 成本结构填报',
        parent: 'FinancialFilling',
        handler: () => {
          Turbolinks.visit("/operation_entry/cost_structure_entry");
        },
      },
      {
        id: 'cash_flow_dept_entry',
        title: '财务分析数据 现金流信息填报',
        parent: 'FinancialFilling',
        handler: () => {
          Turbolinks.visit("/operation_entry/cash_flow_dept_entry");
        },
      },
      {
        id: 'three_categories_manager',
        title: '财务分析数据 三类薪酬人员填报',
        parent: 'FinancialFilling',
        handler: () => {
          Turbolinks.visit("/operation_entry/three_categories_manager");
        },
      },
      {
        id: 'BigCustomerDataEntry',
        title: '大客户数据填报',
        section: '报表',
        children: ['key_customer_project_schedule'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'key_customer_project_schedule',
        title: '大客户施工图项目进度表',
        parent: 'BigCustomerDataEntry',
        handler: () => {
          Turbolinks.visit("/report/key_customer_project_schedule");
        },
      },
      {
        id: 'ManPowerFilling',
        title: '人力数据填报',
        section: '报表',
        children: ['hr_monthly_report_data_entry'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'hr_monthly_report_data_entry',
        title: '人力月报数据填报',
        parent: 'ManPowerFilling',
        handler: () => {
          Turbolinks.visit("/report/hr_monthly_report_data_entry");
        },
      },
      {
        id: 'SocialSecurityFund',
        title: '社保公积金',
        section: '报表',
        children: ['social_security_fund_data_entry','social_security_fund_special','social_security_sharing','provident_fund_sharing','service_fee_sharing'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'social_security_fund_data_entry',
        title: '社保公积金填报',
        parent: 'SocialSecurityFund',
        handler: () => {
          Turbolinks.visit("/operation_entry/social_security_fund_data_entry");
        },
      },
      {
        id: 'social_security_fund_special',
        title: '社保公积金特殊情况填报',
        parent: 'SocialSecurityFund',
        handler: () => {
          Turbolinks.visit("/operation_entry/social_security_fund_special");
        },
      },
      {
        id: 'social_security_sharing',
        title: '社保分摊',
        parent: 'SocialSecurityFund',
        handler: () => {
          Turbolinks.visit("/operation_entry/social_security_sharing");
        },
      },
      {
        id: 'provident_fund_sharing',
        title: '公积金分摊',
        parent: 'SocialSecurityFund',
        handler: () => {
          Turbolinks.visit("/operation_entry/provident_fund_sharing");
        },
      },
      {
        id: 'service_fee_sharing',
        title: '服务费分摊',
        parent: 'SocialSecurityFund',
        handler: () => {
          Turbolinks.visit("/operation_entry/service_fee_sharing");
        },
      },
      {
        id: 'FinancialAnalysisPeriod',
        title: '财务分析周期性报表',
        section: '报表',
        children: ['sign_contract_collection_plan','org_month_fill','business_target_org','dept_month_fill','business_target_dept','cost_provision','mergereport_account_fill',
          'mergereport_analyzer_fill_view','mergereport_analyzer_fill','mergereport_account_fill_view','mergereport_final_check','cost_report','receivable_split_ratio','after_split_receivable','after_split_receivable_edit','factoring_fee_carryover_over_new_year'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'sign_contract_collection_plan',
        title: '财务分析 签约收款计划数据填报',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/sign_contract_collection_plan");
        },
      },
      {
        id: 'org_month_fill',
        title: '财务分析 子公司年度数据填报',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/org_month_fill");
        },
      },
      {
        id: 'business_target_org',
        title: '财务分析 集团营业目标完成情况 （存档表）',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/business_target_org");
        },
      },
      {
        id: 'dept_month_fill',
        title: '财务分析 所级年度数据填报',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/dept_month_fill");
        },
      },
      {
        id: 'business_target_dept',
        title: '财务分析 子公司营业目标完成情况 （存档表）',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/business_target_dept");
        },
      },
      {
        id: 'cost_provision',
        title: '财务分析 财务成本计提表',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/cost_provision");
        },
      },
      {
        id: 'mergereport_account_fill',
        title: '财务分析 合并报表财务填报',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/mergereport_account_fill");
        },
      },
      {
        id: 'mergereport_analyzer_fill_view',
        title: '财务分析 合并报表财务分析查看',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/mergereport_analyzer_fill_view");
        },
      },
      {
        id: 'mergereport_analyzer_fill',
        title: '财务分析 合并报表财务分析填报',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/mergereport_analyzer_fill");
        },
      },
      {
        id: 'mergereport_account_fill_view',
        title: '财务分析 合并报表财务查看',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/mergereport_account_fill_view");
        },
      },
      {
        id: 'mergereport_final_check',
        title: '财务分析 合并报表确认表',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/mergereport_final_check");
        },
      },
      {
        id: 'cost_report',
        title: '财务分析 PM 成本计提表',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/account/cost_report");
        },
      },
      {
        id: 'receivable_split_ratio',
        title: '财务分析 应收账款拆分占比（当年开票）',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/financial_analysis/receivable_split_ratio");
        },
      },
      {
        id: 'after_split_receivable',
        title: '财务分析 应收账款结余（拆分后）',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/financial_analysis/after_split_receivable");
        },
      },
      {
        id: 'after_split_receivable_edit',
        title: '财务分析 应收账款结余（拆分后）编辑',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/financial_analysis/after_split_receivable_edit");
        },
      },
      {
        id: 'factoring_fee_carryover_over_new_year',
        title: '财务分析 跨年保理手续费结转明细（上海天华开票）',
        parent: 'FinancialAnalysisPeriod',
        handler: () => {
          Turbolinks.visit("/financial_analysis/factoring_fee_carryover_over_new_year");
        },
      },
      {
        id: 'DataSet',
        title: '数集数据',
        section: '报表',
        children: ['export_cwfx'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'export_cwfx',
        title: '财务分析数集数据',
        parent: 'DataSet',
        handler: () => {
          Turbolinks.visit("/account/export_cwfx");
        },
      },
      {
        id: 'EstimatedCost',
        title: '预估成本校验',
        section: '报表',
        children: ['estimated_cost_verification_entry','estimated_cost_verification_report','estimated_cost_report','year_estimated_costs_verification','internal_audit_feedback','internal_audit_inquiries'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'estimated_cost_verification_entry',
        title: '财务分析 - 预估成本校验填报',
        parent: 'EstimatedCost',
        handler: () => {
          Turbolinks.visit("/account/estimated_cost_verification_entry");
        },
      },
      {
        id: 'estimated_cost_verification_report',
        title: '财务分析 - 预估成本校验报表',
        parent: 'EstimatedCost',
        handler: () => {
          Turbolinks.visit("/account/estimated_cost_verification_report");
        },
      },
      {
        id: 'estimated_cost_report',
        title: '财务分析 - 预估成本查看',
        parent: 'EstimatedCost',
        handler: () => {
          Turbolinks.visit("/account/estimated_cost_report");
        },
      },
      {
        id: 'year_estimated_costs_verification',
        title: '当年预估成本账面支出明细核对',
        parent: 'EstimatedCost',
        handler: () => {
          Turbolinks.visit("/account/year_estimated_costs_verification");
        },
      },
      {
        id: 'internal_audit_feedback',
        title: '预估成本 - 内审意见及调整反馈',
        parent: 'EstimatedCost',
        handler: () => {
          Turbolinks.visit("/account/internal_audit_feedback");
        },
      },
      {
        id: 'internal_audit_inquiries',
        title: '预估成本 - 内审意见及调整查询',
        parent: 'EstimatedCost',
        handler: () => {
          Turbolinks.visit("/account/internal_audit_inquiries");
        },
      },
      {
        id: 'ManPowerCost',
        title: '人力成本',
        section: '报表',
        children: ['labor_cost','labor_cost_monthly_adjusts','org_market_fees'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'labor_cost',
        title: '人力成本报表',
        parent: 'ManPowerCost',
        handler: () => {
          Turbolinks.visit("/report/labor_cost");
        },
      },
      {
        id: 'labor_cost_monthly_adjusts',
        title: '人力成本调整',
        parent: 'ManPowerCost',
        handler: () => {
          Turbolinks.visit("/report/labor_cost_monthly_adjusts");
        },
      },
      {
        id: 'org_market_fees',
        title: '人力成本 公司市场费管理表',
        parent: 'ManPowerCost',
        handler: () => {
          Turbolinks.visit("/report/org_market_fees");
        },
      },
      {
        id: 'ManPowerSetting',
        title: '人力成本设置',
        section: '报表',
        children: ['set_baseline_job_types','set_part_time_person_costs','set_special_person_costs',
          'monthly_position_users','control_panel','person_position_maintain','bpm_employee_position_changes'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'set_baseline_job_types',
        title: '人力成本设置 设置基准岗位类型',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/set_baseline_job_types");
        },
      },
      {
        id: 'set_part_time_person_costs',
        title: '人力成本设置 兼职人员薪资项目比例设置',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/set_part_time_person_costs");
        },
      },
      {
        id: 'set_special_person_costs',
        title: '人力成本设置 特殊人员薪资项目成本分类设置',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/set_special_person_costs");
        },
      },
      {
        id: 'monthly_position_users',
        title: '人力成本设置 每月员工岗位表',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/monthly_position_users");
        },
      },
      {
        id: 'control_panel',
        title: '人力成本设置 后台进程控制面板',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/control_panel");
        },
      },
      {
        id: 'person_position_maintain',
        title: '人力成本设置 添加缺失月度岗位数据',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/person_position_maintain");
        },
      },
      {
        id: 'bpm_employee_position_changes',
        title: '人力成本设置 从 BPM 传入的职位变动',
        parent: 'ManPowerSetting',
        handler: () => {
          Turbolinks.visit("/cost_split/bpm_employee_position_changes");
        },
      },
      {
        id: 'InternalTrade',
        title: '内部交易',
        section: '报表',
        children: ['monthly_subsidiary_retained_amounts','production_value_bonus_report'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'monthly_subsidiary_retained_amounts',
        title: '内部交易数据查询一览表',
        parent: 'InternalTrade',
        handler: () => {
          Turbolinks.visit("/financial_analysis/monthly_subsidiary_retained_amounts");
        },
      },
      {
        id: 'production_value_bonus_report',
        title: '产值奖金开票报表',
        parent: 'InternalTrade',
        handler: () => {
          Turbolinks.visit("/financial_analysis/production_value_bonus_report");
        },
      },
      {
        id: 'CapitalDaily',
        title: '资金日报',
        section: '报表',
        children: ['fund_daily_fill','fund_daily_fill_missing','summary_fund_daily','summary_fund_week','summary_fund_month',
          'summary_fund_sum','summary_fund_com_sum','salary_query','detail_fund_week','true_management_fee','true_management_fee_sync_cost_structure'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'fund_daily_fill',
        title: '资金日报填报表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/fund_daily_fill");
        },
      },
      {
        id: 'fund_daily_fill_missing',
        title: '资金日报填报缺失人员',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/fund_daily_fill_missing");
        },
      },
      {
        id: 'summary_fund_daily',
        title: '资金日报汇总表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/summary_fund_daily");
        },
      },
      {
        id: 'summary_fund_week',
        title: '资金周报汇总表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/summary_fund_week");
        },
      },
      {
        id: 'summary_fund_month',
        title: '资金月报汇总表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/summary_fund_month");
        },
      },
      {
        id: 'summary_fund_sum',
        title: '集团资金汇总表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/summary_fund_sum");
        },
      },
      {
        id: 'summary_fund_com_sum',
        title: '子公司资金汇总表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/summary_fund_com_sum");
        },
      },
      {
        id: 'salary_query',
        title: '财务部 薪酬查询',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/salary_query");
        },
      },
      {
        id: 'detail_fund_week',
        title: '资金报表',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/detail_fund_week");
        },
      },
      {
        id: 'true_management_fee',
        title: '真实管理费',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/true_management_fee");
        },
      },
      {
        id: 'true_management_fee_sync_cost_structure',
        title: '真实管理费 同步成本结构',
        parent: 'CapitalDaily',
        handler: () => {
          Turbolinks.visit("/capital/true_management_fee_sync_cost_structure");
        },
      },
      {
        id: 'InternalInvoicing',
        title: '内部开票管理',
        section: '报表',
        children: ['production_value_bonus_invoices','company_invoicing_configurations','other_billing_and_inquiries','bank_zip_files','bank_file_details'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'production_value_bonus_invoices',
        title: '产值奖金开票与查询',
        parent: 'InternalInvoicing',
        handler: () => {
          Turbolinks.visit("/financial_analysis/production_value_bonus_invoices");
        },
      },
      {
        id: 'company_invoicing_configurations',
        title: '公司开票配置',
        parent: 'InternalInvoicing',
        handler: () => {
          Turbolinks.visit("/financial_analysis/company_invoicing_configurations");
        },
      },
      {
        id: 'other_billing_and_inquiries',
        title: '其他开票与查询',
        parent: 'InternalInvoicing',
        handler: () => {
          Turbolinks.visit("/financial_analysis/other_billing_and_inquiries");
        },
      },
      {
        id: 'bank_zip_files',
        title: '银行回单文件查询',
        parent: 'InternalInvoicing',
        handler: () => {
          Turbolinks.visit("/financial_analysis/bank_zip_files");
        },
      },
      {
        id: 'bank_file_details',
        title: '银行回单文件明显数据',
        parent: 'InternalInvoicing',
        handler: () => {
          Turbolinks.visit("/financial_analysis/bank_file_details");
        },
      },
      {
        id: 'BudgetExecution',
        title: '预算执行情况',
        section: '报表',
        children: ['budget_managements','income_reports','receive_reports','ad_receive_reports','receivable_details_list','receivable_worksheet','f203'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'budget_managements',
        title: '预算执行情况查询表',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/budget_managements");
        },
      },
      {
        id: 'income_reports',
        title: '预算执行 开票明细表',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/income_report");
        },
      },
      {
        id: 'receive_reports',
        title: '预算执行 实收明细表',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/receive_report");
        },
      },
      {
        id: 'ad_receive_reports',
        title: '预算执行 预收明细表',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/ad_receive_report");
        },
      },
      {
        id: 'receivable_details_list',
        title: '预算执行 应收明细表',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/receivable_details_list");
        },
      },
      {
        id: 'receivable_worksheet',
        title: '预算执行 应收工作表',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/receivable_worksheet");
        },
      },
      {
        id: 'f203',
        title: '统计局 F203 报告',
        parent: 'BudgetExecution',
        handler: () => {
          Turbolinks.visit("/financial_analysis/f203");
        },
      },
      {
        id: 'Cooperation',
        title: '供应商平台',
        section: '报表',
        children: ['cooperation_bill_by_dept','cooperation_bill_by_vendor','cooperation_departmental_payable_summary'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'cooperation_bill_by_dept',
        title: '供应商平台 部门应付账款汇总表',
        parent: 'Cooperation',
        handler: () => {
          Turbolinks.visit("/account/cooperation_bill_by_dept");
        },
      },
      {
        id: 'cooperation_bill_by_vendor',
        title: '供应商平台 项目/合作商应付账款表',
        parent: 'Cooperation',
        handler: () => {
          Turbolinks.visit("/account/cooperation_bill_by_vendor");
        },
      },
      {
        id: 'cooperation_departmental_payable_summary',
        title: '供应商平台 部门应付账款汇总',
        parent: 'Cooperation',
        handler: () => {
          Turbolinks.visit("/account/cooperation_departmental_payable_summary");
        },
      },
      {
        id: 'ExpenseAllocation',
        title: '管理费用分摊',
        section: '报表',
        children: ['cost_split_human_resources','cost_split_split_cost_items','cost_split_allocation_bases','cost_allocation_summary','cost_allocation_monthly_flows'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'cost_split_human_resources',
        title: '管理费用分摊 部门人员人力成本及房租分摊规则设置',
        parent: 'ExpenseAllocation',
        handler: () => {
          Turbolinks.visit("/cost_split/human_resources");
        },
      },
      {
        id: 'cost_split_split_cost_items',
        title: '管理费用分摊 资产及费用预算分摊规则设置',
        parent: 'ExpenseAllocation',
        handler: () => {
          Turbolinks.visit("/cost_split/split_cost_items");
        },
      },
      {
        id: 'cost_split_allocation_bases',
        title: '管理费用分摊 分摊分子基数设置',
        parent: 'ExpenseAllocation',
        handler: () => {
          Turbolinks.visit("/cost_split/allocation_bases");
        },
      },
      {
        id: 'cost_allocation_summary',
        title: '管理费用分摊 集团性费用分摊汇总',
        parent: 'ExpenseAllocation',
        handler: () => {
          Turbolinks.visit("/cost_split/cost_allocation_summary");
        },
      },
      {
        id: 'cost_allocation_monthly_flows',
        title: '管理费用分摊 集团性费用分摊每月审批记录',
        parent: 'ExpenseAllocation',
        handler: () => {
          Turbolinks.visit("/cost_split/cost_allocation_monthly_flows");
        },
      },
      {
        id: 'ITOPreport',
        title: 'IT 运营报告报表',
        section: '报表',
        children: ['tianzhen_logins_0','tianzhen_logins_1','cim_tools','virtual_cloud_desktops','plm_users','xts_users','dds_users'],
        handler: () => {
          // if menu opened that prevent it from closing on select that action, no need if you don't have child actions
          return {keepOpen: true};
        },
      },
      {
        id: 'tianzhen_logins_0',
        title: 'IT报表 天正建筑软件登录情况',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/tianzhen_logins?index=0");
        },
      },
      {
        id: 'tianzhen_logins_1',
        title: 'IT报表 天正日照软件登录情况',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/tianzhen_logins?index=1");
        },
      },
      {
        id: 'cim_tools',
        title: 'IT报表 CIM智能工具使用数据报告',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/cim_tools");
        },
      },
      {
        id: 'virtual_cloud_desktops',
        title: 'IT报表 云渲染用户列表',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/virtual_cloud_desktops");
        },
      },
      {
        id: 'plm_users',
        title: 'IT报表 PLM 用户',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/plm_users");
        },
      },
      {
        id: 'xts_users',
        title: 'IT报表 小天书用户',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/xts_users");
        },
      },
      {
        id: 'dds_users',
        title: 'IT报表 DDS 用户',
        parent: 'ITOPreport',
        handler: () => {
          Turbolinks.visit("/report/dds_users");
        },
      },
      {
        id: 'AccountProfile',
        title: '编辑个人资料',
        section: 'Profile',
        handler: () => {
          Turbolinks.visit("/account/profile");
        }
      },
      {
        id: 'PersonNameCards',
        title: '个人名片',
        section: 'Profile',
        handler: () => {
          Turbolinks.visit("/person/name_cards");
        }
      },
      {
        id: 'AccountJwts',
        title: '管理应用程序及其授权',
        section: 'Profile',
        handler: () => {
          Turbolinks.visit("/account/jwts");
        }
      },
      {
        id: 'AdminHome',
        title: '后台管理首页',
        section: 'Admin',
        handler: () => {
          Turbolinks.visit("/admin");
        }
      },
    ];
  }
}
